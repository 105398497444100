<template>
  <SkeletionNavbar v-if="$store.state && $store.state.loadding"></SkeletionNavbar>
  <section v-else id="copyright-section" class="py-5 text-light">
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <img :src="$store?.state?.siteData.logo" style="height: 38px;">
          <p>
            <br>
            Address : {{$store?.state?.siteData?.address}}
            <br>
            Phone: {{$store?.state?.siteData?.phone}}
            <br>
            Emai: {{$store?.state?.siteData?.email}}
          </p>
          <p>&copy; {{$store?.state?.siteData?.title}}</p>
        </div>

        <div class="col-md-4 text-right">

          <ul class="list-inline social">
             <li class="list-item text-right">

              <a target="_blank" :href="socialLinks.facebook">
                <i class="fa fa-facebook"></i>
              </a>
              <a target="_blank" :href="socialLinks.youtube">
                <i class="fa fa-youtube"></i>
              </a>
              <a target="_blank" :href="socialLinks.linkedin">
                <i class="fa fa-linkedin"></i>
              </a>
              <router-link :to="socialLinks.contact"><i class="fa fa-envelope"></i></router-link>
            </li>
           </ul>
          <p>{{descripton}}</p>
          <p>
            <span v-for="(NavChild, id) in footNav" :key="NavChild.title">
              <a :href="NavChild.link" target="_blank" v-if="NavChild.link.startsWith('http')" class="text-white">{{NavChild.title}}</a>
              <router-link v-else :to="NavChild.link" class="text-white">{{NavChild.title}}</router-link>
              <span v-if="id < footNav.length - 1"> | </span>
            </span>
          </p>
        </div>
        <!-- <div class="col-md-12">
          <a class="d-block text-right text-white pt-2" href="https://www.enrichitsolutions.com">Develop by Enrich IT Solutions</a>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import SkeletionNavbar from "../SkeletionNavbar.vue";
export default {
  name: 'FooterPage',
  data () {
    return {
      socialLinks : {
        facebook  : "#",
        linkedin  : "https://www.linkedin.com/in/mohammad-abdul-hannan-14382031/",
        youtube   : "#",
        contact   : "/contact"
      },
      footNav : [
          {title : "About", link : ""},
          {title : "Terms of Service", link : ""},
          {title : "Privacy Policy", link : ""},
          {title : "Contact", link : ""}
      ],
      descripton : "Expert IT Support When You Need It Most | Dhaka, Bangladesh"
    }
  },
  components : {
    SkeletionNavbar
  }
}
</script>

<style lang="css" scoped>
</style>